$theme-colors: (
  "primary": #00485e,
  "secondary": #02bceb,
);
.snack-bar-succsess {
  color: #4dd36b;
  font-size: 15px;
  font-weight: 500;
  .mat-simple-snackbar-action {
    color: #4dd36b;
    button {
      color: white;
    }
  }
}
.snack-bar-error {
  color: #ff9f9f;
  font-size: 15px;
  font-weight: 500;
  .mat-simple-snackbar-action {
    color: #ff9f9f;
  }
}
