// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "../node_modules/@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-app-primary: mat.define-palette(mat.$indigo-palette);
$client-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$client-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $client-app-primary,
      accent: $client-app-accent,
      warn: $client-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-app-theme);

@import "src/app/styles/globals.scss";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "src/app/styles/theme.scss";

@import "src/app/styles/colors";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";

$modal-lg: 860px;

a {
  color: $primary;
  text-decoration: none;
}

a:hover {
  color: $primary;
}

.navbar.center .navbar-inner {
  text-align: center;
}

.dialog-70-container {
  width: 70%;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}

.successModal .mat-dialog-container {
  padding: 0;
  border-radius: 8px;
}

.yes-no-modal .mat-dialog-container {
  padding: 0;
  border-radius: 8px;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.submit[_ngcontent-jsd-c102] {
  margin: 0 24px 18px 0;
}

/* BUTTONS */
.btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 131px;
  height: 32px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #58595b !important;
  background: rgba(88, 89, 91, 0.3);
  border: none;
}

.btn-no-border {
  height: 32px;
  font-size: 15px;
  font-weight: 500;
  border-right: 4px;
  color: #00485e;
}

.btn-danger {
  height: 32px;
  font-size: 15px;
  font-weight: 500;
  border-right: 4px;
  color: #d9534f;
}
.third-disabled {
  color: #58595b !important;
  opacity: 30%;
  background-color: #f8f8f8 !important;
}

.modal-header {
  border-bottom: none;
  padding: 0;
}

.form-check-input:checked {
  background-color: #00485e;
  border-color: #00485e;
}

/* Scrollbar */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 8px;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00485e !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #00485e !important;
}
.mat-form-field-required-marker { color: red !important }
.disabled-action{
  opacity: 0.3;
}
.mat-checkbox-indeterminate {
  .mat-checkbox-frame{
    border-width: 0;
  }
  .mat-checkbox-background{
    background-color:#58595B !important;
    opacity: 0.5;
  }
}

.status-failed {
  color: #F4646E !important;
}

.status-completed {
  color: #1FA86D !important;
}

.grid-row-no-opacity:hover{
  background: rgba(235,239,240)
}

.spinner {
  display: block;
  position: fixed;
  top: 50%;
  right: 50%;
}
.spinner-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading{
  circle {
    stroke: #b3a369 !important;
  }
}

.icon {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 8px;
  width: 10px;
  position: relative;
  &.loading {
    background-image: url("./assets/icons/spinner.svg");
    width: 16px;
    height: 16px;
  }
}

.snack-bar-success {
  color: #4dd36b;
  font-size: 15px;
  font-weight: 500;
  .mat-simple-snackbar-action {
    color: #4dd36b;
    button {
      color: white;
    }
  }
}
.snack-bar-error {
  color: #ff9f9f;
  font-size: 15px;
  font-weight: 500;

  .mat-simple-snackbar-action {
    color: #ff9f9f;
  }
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #00485E;
}

.transparent .mat-mdc-dialog-container {
  --mdc-dialog-container-elevation-shadow: none !important;
  --mdc-dialog-container-color: rgba(255, 255, 255, 0.0);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: #00485E !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #00485E !important;
}

.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  color: #00485E;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  color: #00485E!important;
}

html {
  --mdc-outlined-text-field-caret-color: #00485E;
  --mdc-outlined-text-field-focus-outline-color: #00485E;
  --mat-select-focused-arrow-color: #00485E;
  --mdc-filled-text-field-caret-color: #00485E;
  --mdc-filled-text-field-focus-active-indicator-color: #00485E;
  --mdc-outlined-text-field-focus-label-text-color: #00485E;
}

.mat-mdc-checkbox-ripple {
  opacity: 0 !important;
}

.form-field {
  margin: 16px 0 0 0;
  padding: 0;
}
.form-field-wrapper {
  width: 350px;
  &.area-code {
    width: 80px;
  }
  &.phone {
    width: 254px;
    margin-left: 16px;
  }
}

.mat-form-field-label-wrapper {
  transform: translateY(-20px) !important;
}
.mat-form-field-label {
  transform: translateY(-20px) !important;
}

#header-image {
  margin-left: 20px;
  margin-top: 15px;
  width: 150px;
  height: 30px;
}

.footer{
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 25px;
  font-size: 13px;
}

#ot-sdk-btn.ot-sdk-show-settings {
  border: none !important;
  color: #00485E !important;
  font-size: 13px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
  color: #00485E !important;
  background-color: white !important;
}

a:hover {
  text-decoration: underline;
}

a {
  font-weight: bold;
}
